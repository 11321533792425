// @flow
import React from "react";
import { Helmet } from "react-helmet";
import { get } from "lodash";

import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/SwipeableDrawer";

import ActionHandler from "components/ActionHandler";
import AudioPlayer from "components/AudioPlayer";
import ButtonLink from "components/ui/ButtonLink";
import Sidebar from "./Sidebar";
import Uploader from "./Uploader";

import tracker from "lib/tracker";

export type StashViewProps = {
  t: Function,
  classes: Object,
  children: any,
  firebase: Object,
  // ui
  ui: {
    showMobileDrawer: boolean
  },
  updateUI: Function,
  // routes
  // data
  songs: Array<Object>,
  hasLoaded: boolean,
  stashNotFound: boolean,
  stash: Object,
  stashId: string
};

type StashViewState = {};

const styles = theme => ({
  desktopContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  desktopGrid: {
    height: "100%",
    display: "flex",
    flexWrap: "nowrap"
  },
  audioContainer: {},
  mobileAudioContainer: {},
  contentContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },
  content: {
    height: 0,
    overflowY: "auto",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      height: "100%",
      maxWidth: 960
    },
    [theme.breakpoints.up("lg")]: {
      height: "100%",
      maxWidth: 1200
    }
  },
  mobileRoot: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  notFoundContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2)
  }
});

class StashView extends React.Component<StashViewProps, StashViewState> {
  componentDidMount() {
    tracker.trackShowStash(this.props.stashId);
  }

  render() {
    const { t, stash } = this.props;
    return (
      <React.Fragment>
        <ActionHandler />
        <Uploader />
        <Helmet title={t("helmTitle", { name: get(stash, "name", "...") })} />
        <Hidden smUp>{this._renderMobile()}</Hidden>
        <Hidden xsDown>{this._renderDesktop()}</Hidden>
      </React.Fragment>
    );
  }

  _renderMobile = () => {
    const { classes, hasLoaded, stashNotFound } = this.props;
    if (stashNotFound) {
      return this._renderNotFound();
    }
    return (
      <React.Fragment>
        {hasLoaded ? this._renderDrawer() : null}
        <div className={classes.mobileRoot}>
          <div className={classes.content}>{this._getChildren()}</div>
          <div className={classes.mobileAudioContainer}>
            <AudioPlayer />
          </div>
        </div>
      </React.Fragment>
    );
  };

  _renderDrawer = () => {
    const { classes, hasLoaded, stash, stashId, songs } = this.props;
    return (
      <nav className={classes.drawer}>
        <Drawer
          variant="temporary"
          onOpen={() => this.props.updateUI("showMobileDrawer", true)}
          open={this.props.ui.showMobileDrawer}
          onClose={this._handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
            modal: classes.drawerModal
          }}
        >
          {hasLoaded ? (
            <Sidebar stash={stash} stashId={stashId} songs={songs} />
          ) : null}
        </Drawer>
      </nav>
    );
  };

  _handleDrawerToggle = () => {
    this.props.updateUI("showMobileDrawer", !this.props.ui.showMobileDrawer);
  };

  _renderDesktop = () => {
    const {
      classes,
      hasLoaded,
      stash,
      stashId,
      songs,
      stashNotFound
    } = this.props;
    if (stashNotFound) {
      return this._renderNotFound();
    }
    return (
      <div className={classes.desktopContainer}>
        <div className={classes.desktopGrid}>
          <div style={{ height: "100%" }}>
            {hasLoaded ? (
              <Sidebar stash={stash} stashId={stashId} songs={songs} />
            ) : null}
          </div>
          <div style={{ flexGrow: 1 }} className={classes.contentContainer}>
            <div className={classes.content}>{this._getChildren()}</div>
          </div>
        </div>
        <div className={classes.audioContainer}>
          <AudioPlayer />
        </div>
      </div>
    );
  };

  _getChildren = () => {
    const { children, hasLoaded, stash, stashId, songs } = this.props;
    if (!hasLoaded) {
      return null;
    }
    return React.cloneElement(children, { stash, stashId, songs });
  };

  _renderNotFound = () => {
    const { classes, t } = this.props;
    return (
      <div className={classes.notFoundContainer}>
        <Typography gutterBottom color="primary" align="center" variant="h4">
          {t("stashNotFound")}
        </Typography>
        <ButtonLink to="/" variant="outlined">
          {t("btnShowStashes")}
        </ButtonLink>
      </div>
    );
  };
}

export default withStyles(styles)(StashView);
