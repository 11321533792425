// @flow
import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";

import PlayIcon from "@material-ui/icons/PlayArrow";
import CloseIcon from "@material-ui/icons/Close";
import PauseIcon from "@material-ui/icons/Pause";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    //  borderTop: `1px solid ${theme.palette.primary.main}`,
    padding: 4,
    position: "relative",
    "&:before, &:after": {
      top: 0,
      content: '""',
      position: "absolute",
      left: 0,
      width: "100%",
      height: 2,
      backgroundColor: "white"
    },
    "&:after": {
      width: props => `${props.playbackPosition}%`,
      backgroundColor: theme.palette.primary.main
    }
  },
  topElements: {
    display: "flex"
  },
  recordingDetails: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  expandContainer: {}
});

const MobileUI = (
  {
    classes,
    show,
    isLoading,
    song,
    recording,
    isPlaying,
    totalLengthTime,
    progressTime,
    playbackPosition,
    currentVolume,
    onSetVolume,
    onStepBack,
    onPause,
    onPlay,
    onStop,
    t
  },
  ref
) => {
  const [isExpanded, setExpanded] = useState(false);
  return (
    <Collapse in={show}>
      <div className={classes.root}>
        <div className={classes.topElements}>
          <div className={classes.left}>
            {isPlaying ? (
              <IconButton disabled={isLoading} onClick={onPause}>
                <PauseIcon />
              </IconButton>
            ) : (
              <IconButton disabled={isLoading} onClick={onPlay}>
                <PlayIcon />
              </IconButton>
            )}
          </div>
          <div
            className={classes.recordingDetails}
            onClick={() => setExpanded(true)}
          >
            {isLoading || !song ? (
              <Typography>{t("loading")}</Typography>
            ) : (
              <React.Fragment>
                <Typography display="block">{recording.name}</Typography>
                <Typography display="block" variant="subtitle2">
                  {song.title}
                </Typography>
              </React.Fragment>
            )}
          </div>
          <div className={classes.right}>
            {isExpanded ? (
              <IconButton
                onClick={() => setExpanded(false)}
                className="pull-right"
              >
                <ExpandMoreIcon />
              </IconButton>
            ) : (
              <IconButton onClick={onStop} className="pull-right">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <Collapse in={isExpanded} className={classes.expandContainer}>
          <div style={{ height: 64 }}>
            <div style={{ height: 64 }} ref={ref} />
          </div>
        </Collapse>
      </div>
    </Collapse>
  );
};

export default withStyles(styles)(React.forwardRef(MobileUI));
