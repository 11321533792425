// @flow
import React from "react";

import { withStyles } from "@material-ui/core/styles";

export type NewVersionDialogProps = {
  newVersionAvailable: boolean,
  registration: ?Object,
  t: Function,
  classes: Object
};

const styles = theme => ({
  title: {
    display: "flex",
    alignItems: "center"
  },
  icon: {
    marginRight: theme.spacing(1)
  }
});

function reload(registration) {
  if (!registration) {
    // only reload if we have a registration
    return;
  }
  if (!registration.waiting) {
    console.log("no waiting");
    // Just to ensure registration.waiting is available before
    // calling postMessage()
    return;
  }
  console.log("new version available");
  registration.waiting.postMessage({ type: "SKIP_WAITING" });
  // eslint-disable-next-line no-restricted-globals
  location.reload();
}

const NewVersionDialog = ({
  t,
  newVersionAvailable,
  registration,
  classes
}: NewVersionDialogProps) => <div>{reload(registration)}</div>;

export default withStyles(styles)(NewVersionDialog);
