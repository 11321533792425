// flow
import { get, set, cloneDeep } from "lodash";
import { ActionType } from "redux-promise-middleware";
import axios from "axios";
import makeDebug from "debug";
import moment from "moment";

const debug = makeDebug("ducks:player");
const ActionTypes = {
  PLAY: "PLAY",
  RESUME: "RESUME",
  PAUSE: "PAUSE",
  STOP: "STOP",
  FINISHED: "FINISHED"
};

const processPeaks = blob => {
  const int16View = new Int16Array(blob);
  const peaks = [];
  for (let i = 0; i < int16View.length; i += 2) {
    const min = int16View[i] / 65536.0;
    const max = int16View[i + 1] / 65536.0;
    peaks.push(max); // max
    peaks.push(min); // min
  }
  return peaks;
};

async function getWaveform(recordingId, getIndexedDb, getFirebase) {
  debug("Get waveform for recordingId", recordingId);
  const getWaveformUrlFunc = getFirebase()
    .functions()
    .httpsCallable("getRecordingWaveformUrl");
  const db = getIndexedDb();
  const cachedWaveform = await db.get("waveforms", recordingId);
  debug("cached waveform", recordingId, cachedWaveform);
  if (
    !cachedWaveform ||
    moment().diff(cachedWaveform.createdAt, "days", true) > 6
  ) {
    debug("no waveform cached. Download..");
    const result = await getWaveformUrlFunc({ recordingId });
    debug("got waveform url", result.data);
    const response = await axios({
      url: result.data,
      responseType: "arraybuffer"
    });
    const peaks = processPeaks(response.data);
    await db.put("waveforms", { createdAt: new Date(), peaks }, recordingId);
    return peaks;
  } else {
    return cachedWaveform.peaks;
  }
}

async function getRecordingUrl(recordingId, getIndexedDb, getFirebase) {
  debug("Get recordingUrl for recordingId", recordingId);
  const getRecordingUrlFunc = getFirebase()
    .functions()
    .httpsCallable("getRecordingUrl");
  const db = getIndexedDb();
  const cachedRecordingUrl = await db.get("recordings", recordingId);
  debug("cached recordingUrl", recordingId, cachedRecordingUrl);
  if (
    !cachedRecordingUrl ||
    moment().diff(cachedRecordingUrl.createdAt, "days", true) > 6
  ) {
    debug("no recording url cached. Request url..");
    const { data } = await getRecordingUrlFunc({ recordingId });
    debug("got recording url", data);
    const recordingUrl = data;
    await db.put(
      "recordings",
      { recordingUrl, createdAt: new Date() },
      recordingId
    );
    return recordingUrl;
  } else {
    return cachedRecordingUrl.recordingUrl;
  }
}

export function playRecording(recordingId) {
  return (dispatch, getState, { getFirebase, getIndexedDb }) => {
    const currentState = getState();
    const isAlreadyPlayingRecording =
      get(currentState, "player.currentRecordingId") === recordingId;
    const hasLoadedOrIsLoading =
      get(currentState, `player.recordings.${recordingId}.isLoading`) ===
        true ||
      get(currentState, `player.recordings.${recordingId}.hasLoaded`) === true;
    if (hasLoadedOrIsLoading) {
      if (isAlreadyPlayingRecording) {
        // just resume
        dispatch({ type: ActionTypes.RESUME, recordingId });
      } else {
        dispatch({
          type: ActionTypes.PLAY,
          recordingId
        });
      }
    } else {
      // new song, fetch url
      const getRecordingPromise = Promise.all([
        getRecordingUrl(recordingId, getIndexedDb, getFirebase),
        getWaveform(recordingId, getIndexedDb, getFirebase)
      ]).then(([recordingUrlResult, waveformResult]) => ({
        url: recordingUrlResult,
        waveform: waveformResult,
        recordingId
      }));
      return dispatch({
        type: ActionTypes.PLAY,
        payload: {
          promise: getRecordingPromise,
          data: {
            recordingId
          }
        }
      });
    }
  };
}

export function resume() {
  return { type: ActionTypes.RESUME };
}

export function finished() {
  return { type: ActionTypes.FINISHED };
}

export function pause() {
  return { type: ActionTypes.PAUSE };
}

export function stop() {
  return { type: ActionTypes.STOP };
}

// #### REDUCER ####
type StateType = {
  state: ?("playing" | "paused" | "stopped"),
  currentRecordingId: ?string
};

export const initialState: StateType = {
  state: null,
  currentRecordingId: null
};

const ACTION_HANDLERS = {
  [`PLAY`]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "playing";
    newState.currentRecordingId = action.recordingId;
    return newState;
  },
  [`PLAY_${ActionType.Pending}`]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "playing";
    newState.currentRecordingId = action.payload.recordingId;
    set(newState, `recordings.${action.payload.recordingId}`, {
      id: action.payload.recordingId,
      isLoading: true,
      hasLoaded: false,
      error: null
    });
    return newState;
  },
  [`PLAY_${ActionType.Fulfilled}`]: (state, action) => {
    if (state.state === "stopped") {
      debug("Already stopped");
      // must happened while loading... do nothing
      return state;
    }
    const newState = cloneDeep(state);
    newState.state = "playing";
    newState.currentRecordingId = action.payload.recordingId;
    set(newState, `recordings.${action.payload.recordingId}`, {
      id: action.payload.recordingId,
      isLoading: false,
      hasLoaded: true,
      error: null,
      url: action.payload.url,
      waveform: action.payload.waveform
    });
    return newState;
  },
  [`PLAY_${ActionType.Rejected}`]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "playing";
    newState.currentRecordingId = null;
    set(newState, `recordings.${action.payload.recordingId}`, {
      id: action.payload.recordingId,
      isLoading: false,
      hasLoaded: false,
      error: action.payload
    });
    return newState;
  },
  [ActionTypes.RESUME]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "playing";
    return newState;
  },
  [ActionTypes.PAUSE]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "paused";
    return newState;
  },
  [ActionTypes.STOP]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "stopped";
    newState.currentRecordingId = null;
    return newState;
  },
  [ActionTypes.FINISHED]: (state, action) => {
    const newState = cloneDeep(state);
    newState.state = "finished";
    return newState;
  }
};

export default function playerReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const getPlayingState = state => state.player.state;
export const hasSelectedRecording = state =>
  !!get(state.player, "currentRecordingId");
export const getCurrentRecording = state =>
  get(state.player, `recordings.${state.player.currentRecordingId}`);
