// @flow
import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export type SimpleInputDialogProps = {
  t: Function,
  classes: Object,
  open: boolean,
  defaultValue?: ?string,
  onClose: () => void,
  onAgree: string => void,
  texts: {
    title: string,
    description: string,
    cancel: string,
    agree: string,
    label: string,
    placeholder: string
  }
};

const style = theme => ({});

const SimpleInputDialog = (props: SimpleInputDialogProps) => (
  <Dialog
    fullWidth
    open={props.open}
    onClose={props.onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <form
      onSubmit={e => {
        e.preventDefault();
        props.onClose();
        props.onAgree(e.target.text.value);
      }}
    >
      <DialogTitle id="alert-dialog-title">{props.texts.title}</DialogTitle>
      <DialogContent>
        {props.texts.description ? (
          <DialogContentText id="alert-dialog-description">
            {props.texts.description}
          </DialogContentText>
        ) : null}
        <TextField
          autoFocus
          variant="outlined"
          margin="normal"
          fullWidth
          name="text"
          defaultValue={props.defaultValue}
          label={props.texts.label}
          placeholder={props.texts.placeholder}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{props.texts.cancel}</Button>
        <Button color="primary" type="submit">
          {props.texts.agree}
        </Button>
      </DialogActions>
    </form>
  </Dialog>
);

SimpleInputDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  texts: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cancel: PropTypes.string.isRequired,
    agree: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string
  })
};

export default withStyles(style)(SimpleInputDialog);
