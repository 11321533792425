import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import sentryMiddleware from "./sentryMiddleware";
import reduxPromiseMiddleware from "redux-promise-middleware";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./rootSaga";
import makeRootReducer from "./reducers";

export default (initialState = {}, sentry, firebase, indexddb) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const sagaMiddleware = createSagaMiddleware({
    context: {
      getFirebase: () => firebase,
      getFirestore: () => firebase.firestore()
    }
  });
  const middleware = [
    thunk.withExtraArgument({
      getFirebase: () => firebase,
      getFirestore: () => firebase.firestore(),
      getIndexedDb: () => indexddb
    }),
    reduxPromiseMiddleware,
    sagaMiddleware,
    sentryMiddleware(sentry)
  ];
  // ======================================================
  // Store Enhancers
  // ======================================================
  const enhancers = [];

  let composeEnhancers = compose;

  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (typeof composeWithDevToolsExtension === "function") {
    composeEnhancers = composeWithDevToolsExtension;
  }

  // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================
  const store = createStore(
    makeRootReducer({}),
    initialState,
    composeEnhancers(applyMiddleware(...middleware), ...enhancers)
  );
  store.asyncReducers = {};
  sagaMiddleware.run(rootSaga);
  if (module.hot && process.env.NODE_ENV !== "production") {
    module.hot.accept("./reducers", () => {
      const reducers = require("./reducers").default;
      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};
