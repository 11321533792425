const sentry = Sentry => store => {
  Sentry.configureScope(scope => {
    scope.addEventProcessor(event => {
      // line below assumes that username is stores under user in the state
      const {
        firebase: { auth },
        ...state
      } = store.getState();

      return {
        ...event,
        extra: {
          ...event.extra,
          "redux:state": state
        },
        user: {
          ...event.user,
          ...auth
        }
      };
    });
  });

  return next => action => {
    Sentry.addBreadcrumb({
      category: "redux-action",
      message: action.type
    });
    return next(action);
  };
};

export default sentry;
