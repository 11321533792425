import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import { reducer as reduxUi } from "react-redux-ui-tools";

import appReducer from "ducks/app";
import uploaderReducer from "ducks/uploader";
import waveformReducer from "ducks/waveforms";
import playerReducer from "ducks/player";

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    ...asyncReducers,
    ui: reduxUi,
    app: appReducer,
    uploader: uploaderReducer,
    waveforms: waveformReducer,
    player: playerReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
