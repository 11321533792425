// @flow
import React from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withErrorBoundary } from "react-error-boundary";
import Loadable from "react-loadable";
import { SnackbarProvider } from "notistack";

import Sentry from "lib/sentry";
// $FlowFixMe
import "moment/locale/de";

import { appTheme } from "theme";
import NewVersionDialog from "components/NewVersionDialog";

import AppError from "components/AppError";
import NotFound from "components/NotFound";
import Routes from "./Routes";
import PrivateRoute from "./PrivateRoute";
import AppLoading from "./AppLoading";

const LoadableSignIn = Loadable({
  loading: () => null,
  loader: () => import("components/pages/SignIn")
});

const routes = () => (
  <Switch>
    {/* AUTH ROUTES */}
    <Route
      exact
      path="/login"
      render={props => <LoadableSignIn {...props} />}
    />
    {/* APP ROUTES */}
    <PrivateRoute path="/" render={props => <Routes {...props} />} />
    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);

const App = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <NewVersionDialog />
      <AppLoading>
        <Router>
          <SnackbarProvider maxSnack={2}>{routes()} </SnackbarProvider>
        </Router>
      </AppLoading>
    </ThemeProvider>
  );
};

const handleBoundaryError = (error, componentStack) => {
  console.error("Boundary error", error);
  Sentry.captureException(error, {
    extra: { errorBoundary: true, componentStack }
  });
};

export default withErrorBoundary(App, AppError, handleBoundaryError);
