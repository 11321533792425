export function secondsToDisplayProgressTime(sec) {
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = Math.round(sec) - hours * 3600 - minutes * 60;
  if (isNaN(hours)) {
    hours = 0;
  }
  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (isNaN(minutes)) {
    minutes = 0;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (isNaN(seconds)) {
    seconds = 0;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  let time = "";
  if (hours > 0) {
    time = `${hours}:${minutes}:${seconds}`;
  } else {
    time = `${minutes}:${seconds}`;
  }
  return time;
}
