import React from "react";
import { withStyles } from "@material-ui/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import CloudUploadIcon from "@material-ui/icons/CloudUploadOutlined";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import CloseIcon from "@material-ui/icons/Close";

const styles = theme => ({
  list: {
    marginBottom: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const Uploader = ({ classes, t, uploads, ui, updateUI }) => {
  const getStateText = upload => {
    switch (upload.state) {
      case "uploading":
        return t("state.uploading", {
          progress: (upload.progress * 100).toFixed(0)
        });
      case "failed":
        return t("state.failed");
      case "queued":
        return t("state.queued");
      case "finished":
        return t("state.finished");
      default:
        return "unknown";
    }
  };
  const getStateIcon = upload => {
    switch (upload.state) {
      case "queued":
        return <CloudUploadIcon color="inherit" />;
      case "uploading":
        return (
          <CircularProgress
            value={upload.progress * 100}
            variant="determinate"
            size={24}
            color="inherit"
          />
        );
      case "finished":
        return <CheckCircleIcon color="inherit" />;
      case "failed":
        return <ErrorIcon color="inherit" />;
      default:
        return null;
    }
  };
  const onClose = () => updateUI("showUploaderDialog", false);
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      onClose={onClose}
      aria-labelledby="uploader-dialog-title"
      open={ui.showUploaderDialog}
    >
      <DialogTitle id="uploader-dialog-title">
        {t("dialogTitle")}
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <List disablePadding className={classes.list}>
          {uploads.map(upload => (
            <ListItem key={upload.id}>
              <ListItemIcon>{getStateIcon(upload)}</ListItemIcon>
              <ListItemText
                primary={upload.file.name}
                secondary={getStateText(upload)}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(withStyles(styles)(Uploader));
