// @flow
import React from "react";
import urljoin from "proper-url-join";
import Avatar from "react-avatar";
import { sortBy } from "lodash";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";

import ExpandMore from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import AddIcon from "@material-ui/icons/Add";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import ArchiveIcon from "@material-ui/icons/ArchiveOutlined";
import AddtoHomescreenIcon from "@material-ui/icons/AddToHomeScreenOutlined";

import ListItemLink from "components/ui/ListItemLink";
import SidebarUploads from "./SidebarUploads";
import PopupMenu from "./PopupMenu";
import SimpleInputDialog from "components/ui/SimpleInputDialog";

export type StashSidebarProps = {
  t: Function,
  theme: Object,
  classes: Object,
  firestore: Object,
  children: any,
  match: Object,
  auth: Object,
  firebase: Object,
  stash: Object,
  stashId: string,
  songs: Array<Object>,
  history: Object,
  profile: Object,
  // ui
  updateUI: Function
};

export type StashSidebarState = {
  anchorEl: ?Object,
  installPrompt: ?Object,
  showNewSong: boolean
};

const styles = theme => ({
  root: {
    height: "100%",
    "& #HW_badge": {
      display: "none !important"
    },
    width: 310,
    display: "flex",
    flexDirection: "column"
  },
  scrollArea: {
    overflow: "auto",
    height: 0,
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2)
  },
  headlineButton: {
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px"
  },
  stashName: {
    fontWeight: 700,
    fontSize: "1.15rem"
  },
  userEmail: {
    // color: theme.kultify.palette.grayC,
    fontWeight: 500
  },
  organizationIcon: {
    // color: theme.kultify.palette.grayC
  },
  menuSelected: {
    backgroundColor: "inherit !important"
  },
  menuText: {
    fontWeight: 500
    // color: theme.kultify.palette.grayF
  },
  btnIcon: {
    marginLeft: -theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  songText: {
    fontWeight: 600
  },
  secondaryMenuText: {
    // color: theme.kultify.palette.grayF,
    fontSize: "0.8rem"
  },
  menuSpace: {
    height: theme.spacing(2),
    flexBasis: theme.spacing(2)
  },
  menuSpaceGrow: {
    flexGrow: 1
  },
  menuIcon: {
    color: theme.riffstash.palette.iconGray
  },
  menuIconSelected: {
    color: theme.riffstash.palette.primary
  },
  subheader: {
    // color: theme.kultify.palette.grayF,
    fontWeight: 600
  },
  archivedIcon: {
    marginRight: theme.spacing(1)
  }
});

class StashSidebar extends React.Component<
  StashSidebarProps,
  StashSidebarState
> {
  constructor(props) {
    super(props);
    this.state = {
      installPrompt: null,
      anchorEl: null,
      hasLoadedHeadway: false,
      updatesUnseen: 0,
      showNewSong: false
    };
  }

  componentDidMount() {
    window.addEventListener("beforeinstallprompt", e => {
      console.log("before installPrompt");
      e.preventDefault();
      this.setState({ installPrompt: e });
    });
  }

  render() {
    const { t, classes, auth, stash, theme } = this.props;
    return (
      <div className={classes.root}>
        <SimpleInputDialog
          onClose={() =>
            this.setState({
              showNewSong: false
            })
          }
          open={this.state.showNewSong}
          onAgree={this._handleNewSong}
          defaultValue={t("newSongDialog.newSongTitle")}
          texts={{
            title: t("newSongDialog.title"),
            agree: t("newSongDialog.agree"),
            cancel: t("newSongDialog.cancel"),
            label: t("newSongDialog.label"),
            placeholder: t("newSongDialog.placeholder")
          }}
        />
        <List disablePadding component="nav">
          <ListItem
            button
            className={classes.headlineButton}
            onClick={event => {
              this.setState({ anchorEl: event.currentTarget });
            }}
          >
            {stash.logoUrl ? (
              <ListItemIcon>
                <Avatar
                  color={theme.palette.primary.main}
                  round="4px"
                  size={40}
                  name={stash.name}
                  src={stash.logoUrl}
                />
              </ListItemIcon>
            ) : null}
            <ListItemText
              primary={stash.name}
              secondary={auth.email}
              classes={{
                primary: classes.stashName,
                secondary: classes.userEmail
              }}
            />
            <ExpandMore color="primary" className={classes.organizationIcon} />
          </ListItem>
        </List>
        {this._renderPopupMenu()}
        <div className={classes.scrollArea}>
          <div className={classes.menuSpace} />
          {/* "Global" Pages etc. */}
          {this._renderStashPages()}
          {this._renderAddToHomescreen()}
          <div className={classes.menuSpace} />
          <Button
            onClick={() => this.setState({ showNewSong: true })}
            variant="outlined"
            fullWidth
            size="small"
            color="primary"
          >
            <AddIcon
              fontSize="inherit"
              className={classes.btnIcon}
              color="inherit"
            />
            {t("menu.btnAddSong")}
          </Button>
          {this._renderSongs()}
          <div className={classes.menuSpace} />
          {this._renderArchivedSongs()}
          <div className={classes.menuSpaceGrow} />
          <SidebarUploads />
        </div>
      </div>
    );
  }

  _renderPopupMenu = () => {
    const { firebase, auth, profile, stash, updateUI } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const handleClose = () => {
      this.setState({ anchorEl: null });
    };
    return (
      <PopupMenu
        auth={auth}
        profile={profile}
        stash={stash}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onLogout={() => firebase.logout()}
        onShowOwnOrganizationSettings={() =>
          updateUI("showOwnOrganizationSettings", true)
        }
        onShowOwnOrganizationNotificationsSettings={() =>
          updateUI("showOwnOrganizationNotificationSettings", true)
        }
      />
    );
  };

  _renderStashPages = () => {
    const { t, classes, match } = this.props;
    const isOverviewPage = match.path === "/s/:stashId";
    const isStashMembers = match.path === "/s/:stashId/members";
    const isStashSettings = match.path === "/s/:stashId/settings";
    return (
      <List disablePadding component="nav">
        <ListItemLink
          button
          onClick={this._handleClickedItem}
          selected={isOverviewPage}
          classes={{ selected: classes.menuSelected }}
          to={this._getStashLink()}
        >
          <ListItemIcon>
            <HomeIcon
              className={
                isOverviewPage ? classes.menuIconSelected : classes.menuIcon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={t("menu.stashOverview")}
            classes={{ primary: classes.menuText }}
          />
        </ListItemLink>
        <ListItemLink
          button
          onClick={this._handleClickedItem}
          selected={isStashMembers}
          classes={{ selected: classes.menuSelected }}
          to={this._getStashLink("members")}
        >
          <ListItemIcon>
            <PeopleIcon
              className={
                isStashMembers ? classes.menuIconSelected : classes.menuIcon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={t("menu.stashMembers")}
            classes={{ primary: classes.menuText }}
          />
        </ListItemLink>
        <ListItemLink
          button
          onClick={this._handleClickedItem}
          selected={isStashSettings}
          classes={{ selected: classes.menuSelected }}
          to={this._getStashLink("settings")}
        >
          <ListItemIcon>
            <SettingsIcon
              className={
                isStashSettings ? classes.menuIconSelected : classes.menuIcon
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={t("menu.stashSettings")}
            classes={{ primary: classes.menuText }}
          />
        </ListItemLink>
      </List>
    );
  };

  _renderAddToHomescreen = () => {
    const { classes, t } = this.props;
    if (!this.state.installPrompt) {
      return;
    }
    return (
      <Hidden mdUp>
        <List disablePadding component="nav">
          <ListItem button onClick={() => this.installPrompt.prompt()}>
            <ListItemIcon>
              <AddtoHomescreenIcon color="secondary" />
            </ListItemIcon>
            <ListItemText
              primary={t("menu.addToHomeScreen")}
              classes={{ primary: classes.menuText }}
            />
          </ListItem>
        </List>
      </Hidden>
    );
  };

  _handleNewSong = async name => {
    const { firestore, stashId, auth } = this.props;
    const songRef = await firestore.add("songs", {
      stashId,
      title: name,
      creatorId: auth.uid,
      createdAt: firestore.FieldValue.serverTimestamp()
    });
    this.props.history.push(`/s/${stashId}/song/${songRef.id}`);
  };

  _renderSongs = () => {
    const { t, classes, songs, stashId, match } = this.props;
    const selectedSongId =
      match.path.startsWith("/s/:stashId/song/:songId") && match.params.songId;
    const activeSongs = songs.filter(s => s.isArchived !== true);
    return (
      <List
        disablePadding
        component="nav"
        subheader={
          <ListSubheader
            disableSticky
            component="div"
            className={classes.subheader}
          >
            {t("menu.headlineSongs")}
          </ListSubheader>
        }
      >
        {sortBy(activeSongs, "title").map(song => (
          <ListItemLink
            dense
            key={`menu-song-entry-${song.id}`}
            button
            onClick={this._handleClickedItem}
            selected={selectedSongId === song.id}
            to={`/s/${stashId}/song/${song.id}`}
          >
            <ListItemText
              primary={song.title}
              classes={{ primary: classes.songText }}
            />
          </ListItemLink>
        ))}
      </List>
    );
  };

  _renderArchivedSongs = () => {
    const { t, classes, songs, stashId, match } = this.props;
    const selectedSongId =
      match.path.startsWith("/s/:stashId/song/:songId") && match.params.songId;
    const archivedSongs = songs.filter(s => s.isArchived === true);
    if (archivedSongs.length === 0) {
      return null;
    }
    return (
      <List
        disablePadding
        component="nav"
        subheader={
          <ListSubheader
            disableSticky
            component="div"
            className={classes.subheader}
          >
            {t("menu.headlineArchived")}
          </ListSubheader>
        }
      >
        {sortBy(archivedSongs, "title").map(song => (
          <ListItemLink
            dense
            key={`menu-song-entry-${song.id}`}
            button
            onClick={this._handleClickedItem}
            selected={selectedSongId === song.id}
            to={`/s/${stashId}/song/${song.id}`}
          >
            <ArchiveIcon className={classes.archivedIcon} fontSize="inherit" />
            <ListItemText
              primary={song.title}
              classes={{ primary: classes.songText }}
            />
          </ListItemLink>
        ))}
      </List>
    );
  };

  _handleClickedItem = () => {
    this.props.updateUI("showMobileDrawer", false);
  };

  _getStashLink = (path: ?string) => {
    return urljoin(`/s/${this.props.stashId}`, path ? path : undefined);
  };
}

export default withStyles(styles, { withTheme: true })(StashSidebar);
