import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { get } from "lodash";

import FullscreenLoader from "components/ui/FullscreenLoader";

function AppLoading({ auth, children, classes }) {
  // Build Todos list if todos exist and are loaded
  if (auth === undefined || get(auth, "isLoaded") === false) {
    return <FullscreenLoader />;
  }
  return children;
}

const enhance = compose(
  connect(state => ({
    auth: state.firebase.auth
  }))
);

export default enhance(AppLoading);
