// @flow
import React from "react";
import WaveSurfer from "wavesurfer.js";
import { get, debounce } from "lodash";
import { reportError } from "lib/sentry";
import { fade } from "@material-ui/core/styles/colorManipulator";

import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import { secondsToDisplayProgressTime } from "lib/durationHelper";

import DesktopUI from "./DesktopUI";
import MobileUI from "./MobileUI";

const styles = theme => ({});

export type InlineAudioPlayerProps = {
  show: boolean,
  hasLoaded: boolean,
  song: ?Object,
  recording: ?Object,
  recordingData: ?Object,
  state: ?string,
  theme: Object,
  stop: Function,
  pause: Function,
  resume: Function
};

type InlineAudioPlayerState = {
  isPlaying: boolean,
  volume: number,
  ready: boolean,
  currentRecordingId: ?string,
  playbackPosition: number
};

class InlineAudioPlayer extends React.Component<
  InlineAudioPlayerProps,
  InlineAudioPlayerState
> {
  waveformRef;
  audioPlayer;

  constructor(props) {
    super(props);
    this.waveformRef = React.createRef();
    this.state = {
      isPlaying: false,
      volume: 0.7,
      ready: false,
      currentRecordingId: null,
      playbackPosition: 0
    };
    this._updateProgress = debounce(this._updateProgress, 200, {
      maxWait: 500
    }).bind(this);
  }

  componentWillUnmount() {
    this._destroyAudioPlayer();
  }

  _destroyAudioPlayer() {
    try {
      this.audioPlayer && this.audioPlayer.destroy();
    } catch (err) {
      reportError(err);
      console.error(err);
    }
    this.audioPlayer = null;
    this._updateProgress(0);
    this.setState({ ready: false });
  }

  componentDidUpdate(prevProps) {
    const changedRecording =
      get(this.props, "recordingData.id") &&
      get(this.props, "recordingData.id") !== this.state.currentRecordingId;
    const changedState = prevProps.state !== this.props.state;
    const changedHasLoaded = prevProps.hasLoaded !== this.props.hasLoaded;
    if (this.audioPlayer && changedRecording) {
      this.audioPlayer.empty();
    }
    if (
      this.audioPlayer &&
      changedState &&
      !changedHasLoaded &&
      !changedRecording &&
      (prevProps.state === "paused" || prevProps.state === "finished") &&
      this.props.state === "playing"
    ) {
      this.audioPlayer.play();
      return;
    }

    if (
      (changedState || changedHasLoaded || changedRecording) &&
      this.props.state === "playing" &&
      this.props.hasLoaded
    ) {
      this._playRecording(this.props.recording, this.props.recordingData);
    }

    if (changedState && this.props.state === "finished" && this.audioPlayer) {
      try {
        this.audioPlayer.stop();
        this._updateProgress(0);
      } catch (err) {
        console.error(err);
      }
    }

    if (
      changedState &&
      this.audioPlayer &&
      this.props.state === "paused" &&
      this.audioPlayer.isPlaying()
    ) {
      this.audioPlayer.pause();
    }

    if (
      this.audioPlayer &&
      this.props.state === "stopped" &&
      this.audioPlayer.isPlaying()
    ) {
      this.audioPlayer.stop();
    }
  }

  _updateProgress = playbackPosition => {
    this.setState({
      playbackPosition: this.audioPlayer
        ? (this.audioPlayer.getCurrentTime() / this.audioPlayer.getDuration()) *
          100
        : playbackPosition
    });
  };

  async _playRecording(recording: Object, recordingData: Object) {
    this._destroyAudioPlayer();
    this._initPlayer();
    this.setState({
      currentRecordingId: recordingData.id
    });
    if (!this.audioPlayer) {
      console.warn("Player not initalized");
      return;
    }
    this.audioPlayer.load(
      recordingData.url,
      recordingData.waveform,
      false,
      recording.durationInSeconds
    );
    this._updateProgress(0);
    await this.audioPlayer.play();
  }

  _initPlayer() {
    if (!this.waveformRef.current) {
      return;
    }
    this.audioPlayer = WaveSurfer.create({
      container: this.waveformRef.current,
      cursorColor: this.props.theme.palette.primary.main,
      waveColor: fade(this.props.theme.palette.primary.main, 0.48),
      progressColor: this.props.theme.palette.primary.main,
      height: 64,
      backend: "MediaElement"
    });
    this.audioPlayer.on("audioprocess", this._updateProgress);
    this.audioPlayer.on("seek", playbackPosition => {
      this.setState({
        playbackPosition:
          (this.audioPlayer.getCurrentTime() / this.audioPlayer.getDuration()) *
          100
      });
    });
    this.audioPlayer.on("volume", volume => this.setState({ volume }));
    this.audioPlayer.on("play", () => this.setState({ isPlaying: true }));
    this.audioPlayer.on("pause", () => this.setState({ isPlaying: false }));
    this.audioPlayer.on("loading", () => this.setState({ isLoading: true }));
    this.audioPlayer.on("ready", () => this.setState({ ready: true }));
    this.audioPlayer.on("finish", () => {
      this.props.finished();
      this.setState({ isPlaying: false });
    });
    this.audioPlayer.setVolume(this.state.volume);
  }

  render() {
    const { t, recording, show, song, recordingData } = this.props;
    const isLoading =
      !recording ||
      !recordingData ||
      recordingData.isLoading ||
      !this.state.ready;
    const playerProps = {
      t,
      show,
      isPlaying: this.state.isPlaying,
      isLoading,
      song,
      recording,
      currentVolume: this.state.volume,
      onSetVolume: this._handleSetVolume,
      progressTime: secondsToDisplayProgressTime(
        this.audioPlayer ? this.audioPlayer.getCurrentTime() : 0
      ),
      playbackPosition: this.state.playbackPosition,
      totalLengthTime:
        this.audioPlayer &&
        secondsToDisplayProgressTime(
          recording.durationInSeconds || this.audioPlayer.getDuration()
        ),
      onStepBack: this._handleStepBack,
      onPause: this._handlePause,
      onPlay: this._handlePlay,
      onStop: this._handleStop,
      ref: this.waveformRef
    };

    return (
      <React.Fragment>
        <Hidden smUp>
          <MobileUI {...playerProps} />
        </Hidden>
        <Hidden xsDown>
          <DesktopUI {...playerProps} />
        </Hidden>
      </React.Fragment>
    );
  }

  _handleSetVolume = volume => {
    this.setState({ volume });
    if (this.audioPlayer) {
      this.audioPlayer.setVolume(volume);
    }
  };

  _handleStepBack = () => {
    this.audioPlayer.seekTo(0);
  };

  _handlePlay = () => {
    this.props.resume();
  };

  _handlePause = () => {
    this.props.pause();
  };

  _handleStop = () => {
    this.props.stop();
  };
}

export default withStyles(styles, { withTheme: true })(InlineAudioPlayer);
