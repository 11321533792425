import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import makeDebug from "debug";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/messaging";
import "firebase/performance";

import { registerServiceWorker } from "lib/registerServiceWorker";
import sentry, { identifyUser, reportError } from "lib/sentry";
import createStore from "store/createStore";
import App from "./components/App";
import i18n from "./lib/i18n";
import { newVersionAvailable } from "ducks/app";
import initIndexedDb from "indexddb";

import tracker from "lib/tracker";

const debug = makeDebug("app");

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

export default async () => {
  // firebase
  firebase.initializeApp(firebaseConfig);
  if (process.env.NODE_ENV !== "development") {
    firebase.functions().region_ = "europe-west1";
  }
  firebase.firestore();
  firebase.functions();
  firebase.storage();
  firebase.performance();
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY);
  }
  if (process.env.NODE_ENV === "development") {
    firebase.functions().useFunctionsEmulator("http://localhost:5000");
  }
  window.firebase = firebase;

  // Store Instantiation
  const initialState = window.__INITIAL_STATE__;
  const db = await initIndexedDb();
  const store = createStore(initialState, sentry, firebase, db);

  registerServiceWorker({
    onUpdate(registration) {
      debug("new version available");
      store.dispatch(newVersionAvailable(registration));
    },
    offline() {
      debug("offline");
    }
  });

  // User Auth Listener
  firebase.auth().onAuthStateChanged(user => {
    try {
      if (user) {
        tracker.identify(user || {});
        identifyUser(user);
      }
    } catch (err) {
      console.error("Could not identify user", err);
      reportError(err);
    }
  });

  // react-redux-firebase config
  const rrfConfig = {
    attachAuthIsReady: true,
    useFirestoreForProfile: true,
    userProfile: "users"
  };
  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance
  };

  // Render Setup
  const MOUNT_NODE = document.getElementById("root");
  let render = (AppElement: React.Component): void => {
    ReactDOM.render(
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <I18nextProvider i18n={i18n}>
            <AppElement />
          </I18nextProvider>
        </ReactReduxFirebaseProvider>
      </Provider>,
      MOUNT_NODE
    );
  };

  // ======================================================== Go!
  render(App);

  if (module.hot && process.env.NODE_ENV !== "production") {
    module.hot.accept("./components/App", () => {
      const NextApp = require("./components/App").default;
      render(NextApp);
    });
  }
};
