import { compose } from "redux";
import { withRouter } from "react-router";
import { withFirebase } from "react-redux-firebase";
import { withTranslation } from "react-i18next";
import { withSnackbar } from "notistack";

import ActionHandler from "./ActionHandler";

import i18n from "lib/i18n";
import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "actionHandler", de, true);
i18n.addResourceBundle("en", "actionHandler", en, true);

export default compose(
  withRouter,
  withFirebase,
  withSnackbar,
  withTranslation("actionHandler")
)(ActionHandler);
