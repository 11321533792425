import amplitude from "amplitude-js";

let apiKey;
let apiEndpoint;
let forceHttps;
if (process.env.NODE_ENV === "production") {
  forceHttps = true;
  apiEndpoint = `europe-west1-riffstash.cloudfunctions.net/amplitudeProxy`;
  apiKey = "94545b19bb3bc1d3a84a08f36c07c994";
} else {
  forceHttps = false;
  apiEndpoint =
    "localhost:5000/riffstash-local-jan-2/europe-west1/amplitudeProxy";
  apiKey = "a09208d20514f1ecb6bb35e2a5f4933c";
}

const instance = amplitude.getInstance();
instance.options.apiEndpoint = apiEndpoint;
instance.options.forceHttps = forceHttps;
instance.init(apiKey);
export default instance;
