import { connect } from "react-redux";
import { get } from "lodash";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { firestoreConnect } from "react-redux-firebase";
import AudioPlayer from "./AudioPlayer";
import { withSnackbar } from "notistack";

import {
  getCurrentRecording,
  hasSelectedRecording,
  getPlayingState,
  stop,
  pause,
  resume,
  finished
} from "ducks/player";

import i18n from "lib/i18n";
import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "audioPlayer", de, true);
i18n.addResourceBundle("en", "audioPlayer", en, true);

const mapStateToProps = (state, ownProps) => {
  const recordingData = getCurrentRecording(state);
  const recording =
    !!recordingData &&
    get(state.firestore.data, `recordings.${recordingData.id}`);
  const recordingSong =
    recording &&
    get(
      state.firestore.data,
      `stash_${recording.stashId}_songs.${recording.songId}`
    );
  return {
    show: hasSelectedRecording(state),
    recording: !!recording && { ...recording, id: recordingData.id },
    recordingData,
    state: getPlayingState(state),
    hasLoaded: !!recording && get(recordingData, "hasLoaded", false),
    song: recordingSong && { ...recordingSong, id: recording.songId }
  };
};

const mapDispatchToProps = {
  stop,
  pause,
  resume,
  finished
};

const query = ({ recordingData }) => {
  if (recordingData) {
    return [
      {
        collection: "recordings",
        doc: recordingData.id,
        storeAs: `recordings`
      }
    ];
  } else {
    return [];
  }
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(query),
  withSnackbar,
  withTranslation("audioPlayer")
)(AudioPlayer);
