import React from "react";
import { withStyles } from "@material-ui/core/styles";

import { ReactComponent as RiffstashLogo } from "./svg/logo.svg";

const styles = theme => ({
  root: {
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center"
  },
  img: {
    width: 128,
    height: 128,
    animationName: "fade_in",
    animationDuration: "2.2s"
  },
  "@keyframes fade_in": {
    from: { opacity: 0 },
    to: { opacity: 1 }
  }
});

const FullscreenLoader = ({ classes }) => (
  <div className={classes.root}>
    <RiffstashLogo className={classes.img} />
  </div>
);

FullscreenLoader.propTypes = {};

export default withStyles(styles)(FullscreenLoader);
