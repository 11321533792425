import browserUpdate from "browser-update";

export default () => {
  const browserUpdateOptions = {
    notify: {
      i: 10,
      f: -4,
      o: -4,
      s: -2,
      c: -4
    },
    unsecure: true,
    api: 5,
    text: "Dein {brow_name}, ist leider veraltet. Your browser is outdated."
  };
  browserUpdate(browserUpdateOptions);
};
