import { compose } from "redux";
import { connect } from "react-redux";
import { withFirestore } from "react-redux-firebase";
import { withRouter } from "react-router";
import ui from "react-redux-ui-tools";
import { withTranslation } from "react-i18next";

import Sidebar from "./Sidebar";

const mapStateToProps = (state, ownProps) => {
  const { auth, profile } = state.firebase;
  return {
    auth,
    profile
  };
};

export default compose(
  withRouter,
  withFirestore,
  connect(mapStateToProps),
  ui(),
  withTranslation("stashView")
)(Sidebar);
