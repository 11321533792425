// @flow
import queryString from "query-string";
import { isArray } from "lodash";
import { reportError } from "lib/sentry";

const acceptInvite = async (firebase, token, enqueueSnackbar, t) => {
  enqueueSnackbar(t("snackbar.acceptInvite"), {
    variant: "default"
  });
  const acceptInviteFunc = firebase
    .functions()
    .httpsCallable("acceptInviteToStash");
  try {
    const result = await acceptInviteFunc({
      token
    });
    enqueueSnackbar(
      t("snackbar.acceptInviteSuccess", {
        name: result.data.stashName
      }),
      {
        variant: "success"
      }
    );
  } catch (err) {
    console.error(err);
    reportError(err);
    enqueueSnackbar(t("snackbar.acceptInviteFailed"), {
      variant: "error"
    });
  }
};

export type ActionHandlerProps = {
  firebase: Object,
  location: Object,
  history: Object,
  enqueueSnackbar: Function,
  t: Function
};

export default ({
  firebase,
  location,
  history,
  enqueueSnackbar,
  t
}: ActionHandlerProps) => {
  const search = queryString.parse(location.search);
  if (!search.action || isArray(search.action)) {
    return null;
  }
  switch (search.action) {
    case "acceptInvite":
      // change history to prevent loops!
      history.replace({
        pathname: location.pathname,
        search: ""
      });
      acceptInvite(firebase, search.token, enqueueSnackbar, t);
      break;
    default:
      reportError(new Error(`Unkown action: ${search.action}`));
  }
  return null;
};
