// @flow
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import PlayIcon from "@material-ui/icons/PlayArrow";
import CloseIcon from "@material-ui/icons/Close";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";

const styles = theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.primary.main}`
  }
});

const AdapterLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const DesktopUI = (
  {
    t,
    classes,
    show,
    isLoading,
    song,
    recording,
    isPlaying,
    totalLengthTime,
    progressTime,
    currentVolume,
    onSetVolume,
    onStepBack,
    onPause,
    onPlay,
    onStop
  },
  ref
) => {
  return (
    <div style={{ padding: 8, display: show ? "block" : "none" }}>
      <Grid container className={classes.root} spacing={2}>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <IconButton disabled={isLoading} onClick={onStepBack}>
            <SkipPreviousIcon />
          </IconButton>
          {isPlaying ? (
            <IconButton disabled={isLoading} onClick={onPause}>
              <PauseIcon />
            </IconButton>
          ) : (
            <IconButton disabled={isLoading} onClick={onPlay}>
              <PlayIcon />
            </IconButton>
          )}
        </Grid>
        <Grid
          item
          style={{ display: "flex", alignItems: "center", minWidth: "10rem" }}
        >
          <VolumeUpIcon style={{ marginRight: 8 }} />
          <Slider
            value={currentVolume * 100}
            onChange={(event, val) => onSetVolume(val / 100)}
          />
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            minWidth: 104
          }}
        >
          {isLoading ? null : (
            <span>
              {progressTime}/{totalLengthTime}
            </span>
          )}
        </Grid>
        <Grid
          item
          xs={3}
          sm={true}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ flex: "1 1 100%" }} ref={ref} />
        </Grid>
        <Grid
          item
          xs={3}
          lg={2}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {isLoading || !song ? (
            <Typography>{t("loading")}</Typography>
          ) : (
            <React.Fragment>
              <Typography display="block">{recording.name}</Typography>
              <Typography display="block" variant="subtitle2">
                <Link
                  component={AdapterLink}
                  to={`/s/${song.stashId}/song/${song.id}`}
                >
                  {song.title}
                </Link>
              </Typography>
            </React.Fragment>
          )}
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          <IconButton onClick={onStop} className="pull-right">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(React.forwardRef(DesktopUI));
