import React from "react";
import { find, filter } from "lodash";
import { connect } from "react-redux";
import ui from "react-redux-ui-tools";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

import CloudUploadIcon from "@material-ui/icons/CloudUploadOutlined";

import { getQueue } from "ducks/uploader";

const styles = theme => ({
  uploadsButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  uploadsButtonIcon: {
    marginRight: theme.spacing(2)
  },
  uploadsButtonContent: {
    flexGrow: 1
  },
  uploadsButtonProgress: {
    paddingBottom: theme.spacing(1)
  }
});

const SidebarUpload = ({ classes, t, uploads, updateUI }) => {
  const currentUpload = find(uploads, { state: "uploading" });
  const currentUploads = filter(uploads, upload =>
    ["uploading", "queued"].includes(upload.state)
  );
  if (uploads.length === 0) {
    return null;
  }
  return (
    <ListItem button onClick={() => updateUI("showUploaderDialog", true)}>
      <ListItemIcon>
        <CloudUploadIcon color="inherit" />
      </ListItemIcon>
      <div className={classes.uploadsButtonContent}>
        <Typography>
          {currentUpload
            ? t("btnShowUploadsActive", { count: currentUploads.length })
            : t("btnShowUploads", { count: uploads.length })}
        </Typography>
        {currentUpload ? (
          <LinearProgress
            className={classes.uploadsButtonProgress}
            variant="determinate"
            color="secondary"
            value={currentUpload.progress * 100}
          />
        ) : null}
      </div>
    </ListItem>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    uploads: getQueue(state)
  };
};

export default React.memo(
  compose(
    connect(mapStateToProps),
    ui(),
    withTranslation("stashView"),
    withStyles(styles)
  )(SidebarUpload)
);
