import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "core-js/features/array/find";
import "core-js/features/array/includes";

import browserUpdate from "./lib/browserUpdate";
import { reportError } from "./lib/sentry";

window.onload = browserUpdate;

const t = `%c██████╗ ██╗███████╗███████╗███████╗████████╗ █████╗ ███████╗██╗  ██╗
██╔══██╗██║██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔══██╗██╔════╝██║  ██║   Built with React, Redux, Firebase and others.
██████╔╝██║█████╗  █████╗  ███████╗   ██║   ███████║███████╗███████║   See our techstack: https://stackshare.io/riffstash/riffstash
██╔══██╗██║██╔══╝  ██╔══╝  ╚════██║   ██║   ██╔══██║╚════██║██╔══██║   Made with ❤️ in Nürnberg
██║  ██║██║██║     ██║     ███████║   ██║   ██║  ██║███████║██║  ██║   Version ${process.env.REACT_APP_VERSION}
╚═╝  ╚═╝╚═╝╚═╝     ╚═╝     ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝`;

function startApp() {
  try {
    const app = require("./app").default;
    console.log(t, "color: #ed3e17");
    app();
  } catch (err) {
    reportError(err);
  }
}

startApp();
