import { connect } from "react-redux";
import { has, get } from "lodash";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { firestoreConnect } from "react-redux-firebase";
import ui from "react-redux-ui-tools";

import StashView from "./StashView";

import i18n from "lib/i18n";
import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "stashView", de, true);
i18n.addResourceBundle("en", "stashView", en, true);

const mapStateToProps = (state, ownProps) => {
  const stashId = ownProps.match.params.stashId;
  const path = `stashes.${stashId}`;
  const songsPath = `stash_${stashId}_songs`;
  const stash = get(state.firestore.data, path);
  const stashNotFound =
    get(state.firestore.status.requested, "stashes") === true && !stash;
  return {
    stashId,
    stash,
    stashNotFound,
    songs: get(state.firestore.ordered, songsPath, []),
    hasLoaded:
      has(state.firestore.data, path) && has(state.firestore.ordered, songsPath)
  };
};

const query = ({ stashId }) => [
  {
    collection: `stashes`,
    doc: stashId,
    storeAs: `stashes`
  },
  {
    collection: "songs",
    where: [["stashId", "==", stashId]],
    storeAs: `stash_${stashId}_songs`
  }
];

const uiConfig = {
  key: "stashView",
  persist: true,
  state: {
    showMobileDrawer: false,
    showUploaderDialog: false
  }
};

export default compose(
  connect(mapStateToProps),
  ui(uiConfig),
  firestoreConnect(query),
  withTranslation("stashView")
)(StashView);
