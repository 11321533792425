// flow
import { call, put, take, getContext, actionChannel } from "redux-saga/effects";
import { map, sortBy } from "lodash";
import { buffers, eventChannel, END } from "redux-saga";
import uuid from "uuid/v4";

import tracker from "lib/tracker";

// ##### ACTIONS #####
export const ActionTypes = {
  UPLOAD_REQUEST: "UPLOAD_REQUEST",
  UPLOAD_PROGRESS: "UPLOAD_PROGRESS",
  UPLOAD_SUCCESS: "UPLOAD_SUCCESS",
  UPLOAD_FAILURE: "UPLOAD_FAILURE"
};
export const uploadRequest = (file: File, meta) => ({
  type: ActionTypes.UPLOAD_REQUEST,
  payload: { file, meta },
  id: uuid()
});
export const uploadProgress = (id, progress: number) => ({
  type: ActionTypes.UPLOAD_PROGRESS,
  payload: progress,
  id
});
export const uploadSuccess = (id, uploadName: string) => ({
  type: ActionTypes.UPLOAD_SUCCESS,
  payload: uploadName,
  id
});
export const uploadFailure = (id, err: Error) => ({
  type: ActionTypes.UPLOAD_FAILURE,
  payload: err,
  error: true,
  id
});

// #### REDUCER ####

export const initialState: StateType = {
  lastQueuePos: 0,
  uploads: {}
};

const ACTION_HANDLERS = {
  [ActionTypes.UPLOAD_REQUEST]: (state, action) => {
    const nextPos = state.lastQueuePos + 1;
    const newState = { ...state };
    newState.uploads[action.id] = {
      queuePos: nextPos,
      file: action.payload.file,
      meta: action.payload.meta,
      state: "queued"
    };
    newState.lastQueuePos = nextPos;
    return newState;
  },
  [ActionTypes.UPLOAD_PROGRESS]: (state, action) => {
    const newState = { ...state };
    newState.uploads[action.id].state = "uploading";
    newState.uploads[action.id].progress = action.payload;
    return newState;
  },
  [ActionTypes.UPLOAD_SUCCESS]: (state, action) => {
    const newState = { ...state };
    newState.uploads[action.id].state = "finished";
    newState.uploads[action.id].progress = 1.0;
    return newState;
  },
  [ActionTypes.UPLOAD_FAILURE]: (state, action) => {
    const newState = { ...state };
    newState.uploads[action.id].state = "failed";
    newState.uploads[action.id].error = action.payload;
    return newState;
  }
};

export default function uploaderReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

// #### GETTERS ####
export function getQueue(state) {
  return sortBy(map(state.uploader.uploads), "queuePos");
}

// #### SAGA ####
export function* uploadRequestWatcherSaga() {
  const requestChannel = yield actionChannel(ActionTypes.UPLOAD_REQUEST);
  while (true) {
    const nextUploadAction = yield take(requestChannel);
    yield call(
      uploadFileSaga,
      nextUploadAction.id,
      nextUploadAction.payload.file,
      nextUploadAction.payload.meta
    );
  }
}
export function* uploadFileSaga(id: string, file: File, meta: Object) {
  const firebase = (yield getContext("getFirebase"))();
  const channel = yield call(createUploadFileChannel, file, meta, firebase);
  while (true) {
    const { progress = 0, err, success, uploadName } = yield take(channel);
    if (err) {
      yield put(uploadFailure(id, err));
      return;
    }
    if (success) {
      yield put(uploadSuccess(id, uploadName));
      return;
    }
    yield put(uploadProgress(id, progress));
  }
}

// #### Upload function ####
function createUploadFileChannel(file, { songId }, firebase) {
  return eventChannel(emitter => {
    const storage = firebase.storage();
    const addRecordingFunc = firebase
      .functions()
      .httpsCallable("addRecordingToSong");
    const auth = firebase.auth();
    const userId = auth.currentUser.uid;
    const uploadName = `${userId}-${uuid()}`;
    const firebaseMetadata = {
      customMetadata: { userId, originalName: file.name }
    };
    const uploadRef = storage.ref().child(`/uploads/${uploadName}`);
    const uploadTask = uploadRef.put(file, firebaseMetadata);
    const unsubscribe = uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes;
        emitter({ progress });
      },
      err => {
        console.error(err);
        emitter({ err });
        emitter(END);
      },
      async () => {
        try {
          const result = await addRecordingFunc({
            name: file.name,
            songId,
            type: "upload",
            uploadPath: uploadName
          });
          tracker.trackUploadedRecording(
            result.data.stashId,
            result.data.songId,
            result.data.recordingId
          );
          emitter({ success: true, uploadName });
        } catch (err) {
          console.error(err);
          emitter({ err });
        }

        emitter(END);
      }
    );
    return () => {
      uploadTask.cancel();
      unsubscribe();
    };
  }, buffers.sliding(2));
}
