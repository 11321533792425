import React from "react";
import { Route, Switch, withRouter } from "react-router";
import Loadable from "react-loadable";
import StashView from "components/StashView";

// Pages ###############################################
import NotFound from "components/NotFound";
// #####################################################

const LoadableProfilePage = Loadable({
  loading: () => null,
  loader: () => import("components/pages/Profile")
});

const LoadableStashesOverview = Loadable({
  loading: () => null,
  loader: () => import("components/pages/StashesOverview")
});

const LoadableStashHome = Loadable({
  loading: () => null,
  loader: () => import("components/pages/StashHome")
});

const LoadableStashSettings = Loadable({
  loading: () => null,
  loader: () => import("components/pages/StashSettings")
});

const LoadableStashMembers = Loadable({
  loading: () => null,
  loader: () => import("components/pages/StashMembers")
});

const LoadableStashSong = Loadable({
  loading: () => null,
  loader: () => import("components/pages/StashSong")
});

const renderWithStashView = Component => props => (
  <StashView {...props}>
    <Component {...props} />
  </StashView>
);

const routes = ({ match }) => (
  <Switch>
    <Route
      exact
      path={`${match.url}`}
      render={props => <LoadableStashesOverview {...props} />}
    />
    <Route
      exact
      path={`${match.url}profile`}
      render={props => <LoadableProfilePage {...props} />}
    />
    {/* STASH */}
    <Route
      exact
      path={`${match.url}s/:stashId`}
      render={renderWithStashView(LoadableStashHome)}
    />
    <Route
      exact
      path={`${match.url}s/:stashId/settings`}
      render={renderWithStashView(LoadableStashSettings)}
    />
    <Route
      exact
      path={`${match.url}s/:stashId/members`}
      render={renderWithStashView(LoadableStashMembers)}
    />
    <Route
      exact
      path={`${match.url}s/:stashId/song/:songId/:tabId?`}
      render={renderWithStashView(LoadableStashSong)}
    />
    {/*
    <Route
      exact
      path={`${match.url}/editCouch/:couchId`}
      render={renderWithDashboardView(LoadableEditCouch)}
    />
    <Route
      exact
      path={`${match.url}/applications/:couchId`}
      render={renderWithDashboardView(LoadableApplications)}
    /> */}

    {/* Not found */}
    <Route component={NotFound} />
  </Switch>
);

export default withRouter(routes);
