import { openDB } from "idb";

const db = openDB("riffstash", 8, {
  upgrade(db, oldVersion, newVersion, tx) {
    if (db.objectStoreNames.contains("waveforms")) {
      tx.objectStore("waveforms").clear();
    } else {
      db.createObjectStore("waveforms");
    }
    if (db.objectStoreNames.contains("recordings")) {
      tx.objectStore("recordings").clear();
    } else {
      db.createObjectStore("recordings");
    }
  }
});

export default async () => await db;
