import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import ui from "react-redux-ui-tools";
import { getQueue } from "ducks/uploader";
import Uploader from "./Uploader";

import i18n from "lib/i18n";
import de from "./i18n/de";
import en from "./i18n/en";
i18n.addResourceBundle("de", "uploader", de, true);
i18n.addResourceBundle("en", "uploader", en, true);

const mapStateToProps = (state, ownProps) => {
  return {
    uploads: getQueue(state)
  };
};

export default compose(
  connect(mapStateToProps),
  ui(),
  withTranslation("uploader")
)(Uploader);
