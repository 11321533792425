// @flow
import makeDebug from "debug";
import amplitude from "./amplitude";

const debug = makeDebug("tracker");
class Tracker {
  track(eventName: string, props?: Object): void {
    debug("track event", eventName, props);
    setTimeout(() => {
      amplitude.logEvent(eventName, props, () =>
        debug("Successful tracked event", eventName, props)
      );
    });
  }

  identify(user: Object) {
    debug("Identify user", { user });
    setTimeout(() => {
      if (user.uid) {
        amplitude.setUserId(user.uid);
        const identify = new amplitude.Identify();
        identify.set("displayName", user.displayName);
        identify.set("email", user.email);
        identify.set("app_version", process.env.REACT_APP_VERSION);
        amplitude.identify(identify);
      }
    });
  }

  // signup and login
  trackSignup(user, providerId) {
    this.track("signup", { user, providerId });
  }
  trackSignIn(user, providerId) {
    this.track("signin", { user, providerId });
  }
  // stashes
  trackShowStashes() {
    this.track("show-stashes");
  }
  trackCreatedStash(stashId) {
    this.track("create-stash", { stashId });
  }
  // stash
  trackShowStash(stashId) {
    this.track("show-stash", { stashId });
  }
  // recordings
  trackUploadedRecording(stashId, songId, recordingId) {
    this.track("recording-uploaded", { stashId, songId, recordingId });
  }
  trackPlayRecording(stashId, recordingId) {
    this.track("recording-play", {
      stashId,
      recordingId
    });
  }
}

export default new Tracker();
