import i18n from "i18next";
import moment from "moment";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ",",
    format: function(value, format, lng) {
      if (format === "uppercase") return value.toUpperCase();
      if (moment.isDate(value) || moment.isMoment(value)) {
        return moment(value)
          .locale(lng)
          .format(format);
      }
      return value;
    }
  },
  react: {
    withRef: true,
    useSuspense: false
  }
});

export default i18n;
