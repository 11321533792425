import "typeface-dosis";
import { createMuiTheme } from "@material-ui/core/styles";

//https://material.io/tools/color/#!/?view.left=1&view.right=0&primary.color=ed3e17&secondary.color=4FC3F7
// Riffstash COLORS
const PRIMARY_COLOR = "#ed3e17";
const SECONDARY_COLOR = "#4fc3f7";

export const appTheme = createMuiTheme({
  riffstash: {
    palette: {
      primary: PRIMARY_COLOR,
      iconGray: "#6E6E6E"
    }
  },
  typography: {
    fontSize: 16,
    // Use the system font instead of the default Roboto font.
    fontFamily: ["Dosis", "Helvetica", "Arial", "sans-serif"]
  },
  palette: {
    type: "dark",
    background: {
      default: "#202021"
    },
    primary: {
      main: PRIMARY_COLOR
    },
    secondary: {
      main: SECONDARY_COLOR
    },
    warning: {
      main: "#FF1000"
    }
  },
  shape: {
    borderRadius: 8
  }
});
