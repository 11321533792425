export default {
  helmTitle: "{{name}} ~ Riffstash",
  menu: {
    stashOverview: "Home",
    stashMembers: "Members",
    stashSettings: "Settings",
    headlineSongs: "Songs",
    headlineArchived: "Archived",
    btnAddSong: "Add Song",
    updates: "What's new?",
    addToHomeScreen: "Add to home screen",
    headway: {
      title: "What is new?",
      readMore: "Read more",
      labels: {
        new: "Updates",
        improvement: "Improvements",
        fix: "Fixes"
      },
      footer: "Read more 👉"
    }
  },
  newSongDialog: {
    newSongTitle: "New Song",
    title: "Add Song",
    agree: "Create",
    cancel: "Cancel",
    label: "Song title",
    placeholder: "Input a cool name..."
  },
  popupMenu: {
    itemChangeStash: "Change stash",
    itemProfile: "Profile",
    itemLogout: "Logout",
    subtitleYourStash: "Your Stash"
  },
  btnShowUploads: "Show Uploads ({{count}})",
  btnShowUploadsActive: "({{count}}) Uploading...",
  stashNotFound: "Stash not found",
  btnShowStashes: "Show stashes"
};
