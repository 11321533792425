export default {
  helmTitle: "{{name}} ~ Riffstash",
  menu: {
    stashOverview: "Übersicht",
    stashMembers: "Mitglieder",
    stashSettings: "Einstellungen",
    headlineSongs: "Songs",
    headlineArchived: "Archiviert",
    updates: "Was ist neu?",
    addToHomeScreen: "Zum Homescreen hinzufügen",
    headway: {
      title: "Was ist neu?",
      readMore: "Mehr lesen",
      labels: {
        new: "Neuigkeiten",
        improvement: "Verbesserungen",
        fix: "Fehlerbehebungen"
      },
      footer: "Mehr lesen 👉"
    },
    btnAddSong: "Neuer Song"
  },
  newSongDialog: {
    newSongTitle: "Neuer Song",
    title: "Neuer Song",
    agree: "Erstellen",
    cancel: "Abbrechen",
    label: "Song-Titel",
    placeholder: "Gib einen Namen ein..."
  },
  popupMenu: {
    itemChangeStash: "Stash wechseln",
    itemProfile: "Profil",
    itemLogout: "Abmelden",
    subtitleYourStash: "Dein Stash"
  },
  btnShowUploads: "Uploads anzeigen ({{count}})",
  btnShowUploadsActive: "({{count}}) Wird hochgeladen...",
  stashNotFound: "Stash nicht gefunden",
  btnShowStashes: "Stashes anzeigen"
};
