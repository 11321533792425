import * as Sentry from "@sentry/browser";
import makeDebug from "debug";
import has from "lodash/has";

const debug = makeDebug("sentry");
let dsn = "";

if (process.env.NODE_ENV === "production") {
  dsn = "https://f68592a002d2464487810278fb197cfa@sentry.io/1432348";
  debug(
    `Sentry enabled. DSN: ${dsn}. App Version: ${
      process.env.REACT_APP_VERSION
    }. Environment: ${process.env.REACT_APP_ENVIRONMENT}`
  );
} else {
  debug("Not using sentry because of development mode");
}

Sentry.init({
  dsn,
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  release: process.env.REACT_APP_VERSION || "development"
});

export function reportError(err, showReportDialog = false) {
  const isNetworkError =
    has(err, "crossDomain") && has(err, "method") && has(err, "url");
  if (process.env.NODE_ENV === "production") {
    debug(
      "Capture error with raven (sentry)",
      err,
      showReportDialog,
      isNetworkError
    );
    Sentry.captureException(err);
    if (showReportDialog && isNetworkError === false) {
      Sentry.showReportDialog();
    }
  } else {
    console.warn("Not reporting following error:");
    console.error(err);
    debug(
      "Don't capture error with raven (sentry) in non-production",
      err,
      showReportDialog,
      isNetworkError
    );
  }
}

export function handleReactError(error: Error, componentStack: string) {
  debug("Capture react error", error, componentStack);
  Sentry.withScope(scope => {
    scope.setExtra("componentStack", componentStack);
    Sentry.captureException(error);
  });
}

export function identifyUser(user) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: user.uid, email: user.email });
  });
}

export default Sentry;
