// @flow
import React from "react";
import { withTranslation } from "react-i18next";
import Avatar from "react-avatar";

import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import Divider from "@material-ui/core/Divider";

import ListItemLink from "components/ui/ListItemLink";

export type PopupMenuProps = {
  t: Function,
  classes: Object,
  children: any,
  anchorEl: ?Object,
  open: boolean,
  auth: Object,
  stash: Object,
  profile: Object,
  // funcs
  onClose: () => void,
  onLogout: () => void
};

const styles = theme => ({
  root: {
    minWidth: "25rem"
  }
});

export const PopupMenu = ({
  t,
  theme,
  classes,
  children,
  open,
  anchorEl,
  auth,
  stash,
  profile,
  // funcs
  onClose,
  onLogout
}: PopupMenuProps) => (
  <Popover
    id="popup-menu"
    open={open}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left"
    }}
  >
    <div className={classes.root}>
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar
              round="4px"
              size={40}
              name={profile.photoUrl ? null : profile.displayName}
              email={profile.photoUrl ? null : profile.email}
              src={profile.photoUrl}
            />
          </ListItemIcon>
          <ListItemText
            primary={profile.displayName}
            secondary={profile.email}
          />
        </ListItem>
        <ListItemLink button to="/profile">
          <ListItemText primary={t("popupMenu.itemProfile")} />
        </ListItemLink>
        <ListItem dense button>
          <ListItemText
            primary={t("popupMenu.itemLogout")}
            onClick={onLogout}
          />
        </ListItem>
      </List>
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar
              color={theme.palette.primary.main}
              round="4px"
              name={stash.name}
              size={40}
              src={stash.logoUrl}
            />
          </ListItemIcon>
          <ListItemText
            primary={stash.name}
            secondary={t("popupMenu.subtitleYourStash")}
          />
        </ListItem>
      </List>
      <Divider />
      <List dense>
        <ListItemLink button to="/">
          <ListItemText primary={t("popupMenu.itemChangeStash")} />
        </ListItemLink>
      </List>
    </div>
  </Popover>
);

export default withStyles(styles, { withTheme: true })(
  withTranslation("stashView")(PopupMenu)
);
