import { ActionType } from "redux-promise-middleware";
import axios from "axios";
import { get, has } from "lodash";

const initialState = {
  waveforms: {}
};

const processPeaks = blob => {
  const int16View = new Int16Array(blob);
  const peaks = [];
  for (let i = 0; i < int16View.length; i += 2) {
    const min = int16View[i] / 65536.0;
    const max = int16View[i + 1] / 65536.0;
    peaks.push(max); // max
    peaks.push(min); // min
  }
  return peaks;
};

export function loadWaveform(recordingId) {
  return (dispatch, getState, { getFirebase, getIndexedDb }) => {
    console.log("load waveform", recordingId);
    const getWaveformUrlFunc = getFirebase()
      .functions()
      .httpsCallable("getRecordingWaveformUrl");
    async function getWaveform() {
      const db = getIndexedDb();
      let waveform = await db.get("waveforms", recordingId);
      console.log("cached waveform", recordingId);
      if (!waveform) {
        console.log("no waveform cached. Download..");
        const result = await getWaveformUrlFunc({ recordingId });
        console.log("waveform url", result.data);
        const response = await axios({
          url: result.data,
          responseType: "arraybuffer"
        });
        const peaks = processPeaks(response.data);
        await db.put("waveforms", peaks, recordingId);
        return peaks;
      } else {
        return waveform;
      }
    }
    const getWaveformPromise = getWaveform().then(data => ({
      waveform: data,
      recordingId
    }));
    return dispatch({
      type: "LOAD_WAVEFORM",
      payload: {
        promise: getWaveformPromise,
        data: {
          recordingId
        }
      }
    });
  };
}

const ACTION_HANDLERS = {
  // LOAD
  [`LOAD_WAVEFORM_${ActionType.Pending}`]: (state, action) => ({
    ...state,
    waveforms: {
      ...state.waveforms,
      [action.payload.recordingId]: {
        isLoading: true
      }
    }
  }),
  [`LOAD_WAVEFORM_${ActionType.Fulfilled}`]: (state, action) => ({
    ...state,
    waveforms: {
      ...state.waveforms,
      [action.payload.recordingId]: {
        isLoading: false,
        waveform: action.payload.waveform
      }
    }
  }),
  [`LOAD_WAVEFORM_${ActionType.Rejected}`]: (state, action) => ({
    ...state,
    waveforms: {
      ...state.waveforms,
      [action.payload.recordingId]: {
        isLoading: false,
        error: action.payload.error
      }
    }
  })
};

export default function waveformReducer(
  state: ?StateType = initialState,
  action: Object
) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}

export const isLoadingWaveform = (state, recordingId) =>
  get(state.waveforms.waveforms, `${recordingId}.isLoading`);

export const hasWaveformLoadError = (state, recordingId) =>
  has(state.waveforms.waveforms, `${recordingId}.error`);

export const getWaveform = (state, recordingId) =>
  get(state.waveforms.waveforms, `${recordingId}.waveform`);
